import { datadogLogs } from '@datadog/browser-logs';
import pino from 'pino';
import { inspect } from 'util';
import { environment } from '../api/http';

// USAGE
// Create a logger.ts for your app, which creates and exports a logger instance.
// export const logger = createLogger("serviceName");

// https://github.com/pinojs/pino-pretty
const prettyPrintSettings =
  environment === 'local' ? { colorize: true } : false;

const formatters = {
  level(label: string, _number: number) {
    return { level: label };
  },
};

let logLevel: string;
switch (environment) {
  case 'local':
    logLevel = 'trace';
    break;
  case 'exp':
  case 'dev':
    logLevel = 'debug';
    break;
  case 'stage':
  case 'prod':
    logLevel = 'info';
    break;
  default:
    throw new Error('Unexpected environment!');
}

export const loggers: Map<string, pino.Logger> = new Map();

let errorFunction: any;
export const setErrorFunction = function (arg: any) {
  errorFunction = arg;
};

export const createLogger = function (
  name: string,
  discardLogs: boolean = false,
) {
  const options: pino.LoggerOptions = {
    name: name,
    level: logLevel,
    prettyPrint: prettyPrintSettings,
    formatters,
    hooks: {
      // Right now error function is only set in
      // Roam Electron, but this makes it so logger.error and
      // logger.fatal in the main process can send
      // an IPC to the logger window to log the error
      logMethod(inputArgs, method, level) {
        if (discardLogs) {
          return;
        }
        // pass errors and warns to errorFunction
        if (level >= 40 && errorFunction) {
          errorFunction(inputArgs);
        }
        if (inputArgs.length > 0) {
          // these shenanigans needed to make the
          // types work out
          const arg1 = inputArgs.shift();
          return method.apply(this, [arg1, ...inputArgs]);
        }
      },
    },
    // This causes logger.error and logger.fatal calls
    // in browser side code to send to datadog if it is configured
    browser: {
      transmit: {
        send: function (level, logEvent) {
          // log errors to datadog
          if (datadogLogs.logger && logEvent.level.value >= 50) {
            datadogLogs.logger.error(
              `app error ${inspect(logEvent, { depth: 5 })}`,
            );
          }
        },
      },
    },
  };
  const logger = discardLogs
    ? pino(options, {
        write: (msg: string) => {},
      })
    : pino(options);
  loggers.set(name, logger);
  return logger;
};

// Please use this logger only for common/* files.
// Apps should create and use their own logger instance.
export const commonLogger = createLogger('common');

export const beforeDataDogSend = (log: any) => {
  const str = JSON.stringify(log);
  if (
    /* Errors that can be safely ignored */
    str.includes('ResizeObserver loop limit exceeded') ||
    str.includes('The operation could not be performed and was aborted') ||
    str.includes('Requested device not found') ||
    str.includes('Possible EventEmitter')
  ) {
    return false;
  }
};
