import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { ethers } from 'ethers';
import DingDongCollection from '../artifacts/contracts/DingDongCollection.sol/DingDongCollection.json';
import NftMetadata from '../../../shared/features/crypto/NftMetadata';
import ethereumProvider from '../../../shared/features/crypto/ethereumProvider';

type NftRequest = {
  contractAddress: string;
  tokenId: number;
};

/**
 * Queries an (Ethereum) blockchain for contracts, NFTs, etc.
 * Also queries for related off-chain resources, e.g. NFT metadata.
 */
const cryptoApi = createApi({
  reducerPath: 'cryptoApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '',
  }),
  endpoints: (builder) => ({
    /**
     * Queries the blockchain for an NFT's token URI.
     */
    fetchTokenUri: builder.query<string, NftRequest>({
      queryFn: async ({ contractAddress, tokenId }) => {
        const contract = new ethers.Contract(
          contractAddress,
          DingDongCollection.abi,
          ethereumProvider,
        );

        return await contract
          .tokenURI(tokenId)
          .then((result) => ({
            data: result,
          }))
          .catch((err) => ({
            error: err,
          }));
      },
    }),
    fetchTokenOwner: builder.query<string, NftRequest>({
      queryFn: async ({ contractAddress, tokenId }) => {
        const contract = new ethers.Contract(
          contractAddress,
          DingDongCollection.abi,
          ethereumProvider,
        );

        return await contract
          .ownerOf(tokenId)
          .then((result) => ({
            data: result,
          }))
          .catch((err) => ({
            error: err,
          }));
      },
    }),
    fetchNftMetadata: builder.query<NftMetadata, string>({
      query: (uri) => ({
        url: uri,
      }),
    }),

    fetchTokenTransferHistory: builder.query<
      ethers.providers.Log[],
      NftRequest
    >({
      queryFn: async ({ contractAddress, tokenId }) => {
        const contract = new ethers.Contract(
          contractAddress,
          DingDongCollection.abi,
          ethereumProvider,
        );

        const filter = {
          ...contract.filters.Transfer(null, null, tokenId),
          fromBlock: 'earliest',
        };
        return await ethereumProvider
          .getLogs(filter)
          .then((result) => ({
            data: result,
          }))
          .catch((err) => ({
            error: err,
          }));
      },
    }),
  }),
});
export default cryptoApi;
